// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getHostList, membersAdd, getUserGroupByUserType } from '@/services/members.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      setupActive: 0,
      btnTxt: this.$t('button.next'),
      groupTypeOptions: [
        {
          name: this.$t('form.groupUser.retail'),
          value: 'retail'
        },
        {
          name: this.$t('form.groupUser.wholesale'),
          value: 'wholesale'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.formData[0].createDateItem[6].options = this.groupTypeOptions
      getHostList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[5].options = res.data.host_list
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      if (prop === 'user_type') {
        this.getUserGroup(data)
        if (data === 'wholesale') {
          this.formData[0].createDateItem[3].Object.rules[0].required = true
          this.formData[0].createDateItem[7].Object.rules[0].required = true
        }
      }
    },
    getUserGroup (data = null) {
      getUserGroupByUserType(data).then(res => {
        this.formData[0].createDateItem[7].options = res.data.user_groups
      })
    },
    formSubmitUp (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        user: obj
      }
      membersAdd(params).then(res => {
        if (res.status >= 200 && res.status < 300) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'managerAddress',
              params: {
                id: res.data.user.id
              }
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit () {
      this.$refs.alterForm.handleSubmitClick()
    },
    handleCancelClick () {
      this.$router.back(-1)
    }
  }
}
