<template>
  <div>
    <el-card>
      <el-steps :active="setupActive" align-center finish-status="success" class="mb20">
        <el-step :title="$t('text.basicInfo')"></el-step>
        <el-step :title="$t('text.managerAddress')"></el-step>
      </el-steps>
       <alter-form  :customeButton="true" :labelWidth="'120px'" @handleChange="handleChange" ref="alterForm" @formSubmit="formSubmitUp" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength">
         <template v-slot:customeButton="data">
           <el-button @click="handleCancelClick">{{$t('button.cancel')}}</el-button>
           <el-button type="primary" @click="formSubmit(data)">{{ $t('button.next') }}</el-button>
         </template>
       </alter-form>
    </el-card>
  </div>
</template>

<script>
import onlineCouponEdit from './index.js'
export default onlineCouponEdit
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
